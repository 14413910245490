import React, { useEffect, useState , useRef } from "react";
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import ic_copy from '../assets/images/icon/AiOutlineCopy.svg'
import { FiPlusCircle } from "react-icons/fi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { Inputfield, InputNumber, Optionfield ,InputEmailfield ,InputPhoneNumber ,OptionDistricfield} from "../components/tsfillter/fillter.js"
import { useForm, FormProvider } from "react-hook-form";
import { useSelector, useDispatch ,shallowEqual } from "react-redux";
import { GenerateUrlPayment, getUrlPayment, Getstatus } from "../reducers/urlpayment.reducer";
import cityjson from '../assets/json/city.json';
import districtjson from '../assets/json/district.json';

// import { FaSleigh } from "react-icons/fa";

const UrlPayment = () => {
    const [displayurl, setdisplayurl] = useState();
    const [displayError, setDisplayError] = useState();
    const [optionCity , setOptionCity] = useState();
    const dispatch = useDispatch();
    const methods = useForm();

    const url = useSelector(getUrlPayment);
    const { userProfile } = useSelector(state => state.auth, shallowEqual);
    const status = useSelector(Getstatus);

    const onchangeCity = data =>{
        setOptionCity(data)
    }

    const onSubmit = data => {
        data.is_redirect = false
        data.merchant_id = String(userProfile?.merchant_id) 
        dispatch(GenerateUrlPayment(data))
    }   
    const divRef = useRef(null);    
  
    useEffect(() => {
        if (status && url?.data?.url) {
            setdisplayurl(url?.data?.url)
            setTimeout(() => 
            divRef.current.scrollIntoView({ behavior: 'smooth' }), 1000)

        }
        else if (!status && status!=null) {
            setTimeout(() => 
            divRef.current.scrollIntoView({ behavior: 'smooth' }), 1000)
            setDisplayError(url?.message)
        }
    }, [status])

    const genderList = [
        { label: "Nam", value: "M" },
        { label: "Nữ", value: "F" },
    ]
    const country = [
        { label :"Viet Nam" , value:"VN"}
    ]
    const city = cityjson
    const district = districtjson

    return (
        <>
            <SectionTitle title="URL Payment" subtitle="URL Payment" />
            <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Widget>
                        <div className="flex flex-row gap-10">
                            <div className="flex flex-col gap-4 w-full items-end">
                                <Inputfield field="order_id" label="Số đơn hàng" placeholder="Nhập số đơn hàng" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true}/>
                                <InputNumber field="amount" label="Giá trị thanh toán" placeholder="Nhập giá trị thanh toán" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true} />
                            </div>
                            <div className="flex flex-col mr-20  gap-4 w-full items-end">
                                <Inputfield field="merchant_name" label="Tên merchant" placeholder="Nhập tên merchant" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true} val={userProfile?.merchant_name}/>
                            </div>
                        </div>
                        <div className="flex flex-row mt-6 gap-10">
                            <div className="flex flex-col gap-4 w-full items-end">
                                <Inputfield field="last_name" label="Họ" placeholder="Nhập họ" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true}/>
                                <Optionfield field="gender" label="Giới Tính" option={genderList} styleoption="w-2/3" wraperstyle="justify-end w-full" required={true}/>
                                <Optionfield field="city" label="Tỉnh/Thành Phố" option={city} type="json" placeholder="Chọn Tỉnh/Thành Phố" styleoption="w-2/3" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true} onchangeCity={onchangeCity}/>
                                <Inputfield field="address" label="Địa chỉ" placeholder="Nhập địa chỉ" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true}/>
                                <InputEmailfield field="email" label="Email" placeholder="Nhập Email" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true}/>
                            </div>
                            <div className="flex flex-col gap-4 w-full items-end  mr-20">
                                <Inputfield field="first_name" label="Tên" placeholder="Nhập tên" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true}/>
                                <InputPhoneNumber field="mobile" label="Số Điện Thoại" placeholder="Nhập Số Điện Thoại" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true}/>
                                <OptionDistricfield field="district" label="Quận/Huyện" placeholder="Chọn Quận/Huyện" city={optionCity} option={district} styleoption="w-2/3" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true}/>
                                <Optionfield field="country" label="Quốc gia" option={country} styleoption="w-2/3" styleinput="w-2/3" wraperstyle="justify-end w-full" required={true} dis={true}/>
                                <Inputfield field="description" label="Nội dung đơn hàng" placeholder="Nhập nội dung đơn hàng" styleinput="w-2/3" wraperstyle="justify-end w-full"/>
                            </div>
                        </div>

                    </Widget>
                    <div className="flex justify-center gap-8 mt-8">
                        <button type="submit" className="px-6 py-3 text-white bg-blue-500 flex items-center gap-2 rounded-md"><FiPlusCircle className="text-white h-4 w-4" />Tạo link thanh toán</button>
                    </div>
                </form>
            </FormProvider>
            {displayurl && status && (
                <div ref={divRef} className="mt-8">
                    <Widget>
                        <div className="flex items-center w-1/2 gap-2">
                            <p className=" w-40 text-sm">Link thanh toán</p>
                            <div className="flex w-full p-2 border border-[#B1BECF] text-sm leading-5">
                                <input className="w-full" disabled value={displayurl} />
                                <img onClick={() => {navigator.clipboard.writeText(displayurl)}} className="cursor-pointer" src={ic_copy} />
                            </div>
                        </div>
                    </Widget>
                </div>
            )}
            {displayError && !status && status!=null && (
                <div ref={divRef} className="flex gap-2 w-fit px-10 py-2 bg-red-error-bg justify-center items-center mt-8 mx-auto rounded-md">
                    <BsFillExclamationTriangleFill className="text-red-primary h-4 w-4" />
                    <p className=" text-red-primary">{displayError}</p>
                </div>
            )}
        </>
    );
};
export default UrlPayment;