import React from "react";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import { FiSettings } from "react-icons/fi";
import { MdHistory } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { FiArrowDown } from "react-icons/fi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { useEffect, useState } from "react";



const Index = () => {
  return (
    <>
      <SectionTitle title="Pages" subtitle="Bank Balance" />
      <Widget>
        <div className="rounded-md items-center w-1/3 h-full m-10 border border-gray-300">
          <div className="flex justify-center relative h-1/3 mt-10 ">
            <div className="w-2/3 rounded-md border border-slate-600 text-white " style={{ backgroundImage: "url(/images/background.jpeg)" }}>
              <p className="mt-2 text-right mr-3">VALENS</p>
              <div className="flex mt-2 ml-4 space-x-5">
                <AiFillCaretLeft className="h-6 w-6 mt-4" />
                <img
                  className="h-12 w-12 rounded-md mt-1"
                  src="/images/card.png"
                  alt="card"
                />
                <div className="text-[14px] mt-2">
                  <p>TRAVEL</p>
                  <p>MILES</p>
                </div>
              </div>

              <p className="mt-2 ml-4 text-[24px]">5480 6000 8231 9314</p>
              <p className="text-[12px] mt-2 ml-4">VALID THRU 03/22</p>
              <div className="flex justify-between">
                <div className="mt-3 mb-4">
                  <p className="ml-4 text-[16px]">HOANG DINH THANH</p>
                </div>
                <div className="mr-2">
                  <p>VISA</p>
                  <p>Platinum</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <div className="rounded-r-md w-2/5 mt-5 text-[25px] flex border border-zinc-600">
                <div className="rounded-r-md w-1/3 h-[80px] flex my-3">
                  <p className="">a</p>
                  <div className=" rounded-md h-[40px] w-[40px] m-5 ml-6">
                    <RiMoneyDollarCircleLine className="m-2" />
                  </div>
                </div>
                <div>
                  <p className="ml-8 mt-4 text-[#8b5cf6] text-[14px] ">
                    BALANCE
                  </p>
                  <p className="ml-8">$17,080</p>
                </div>
              </div>
              <div className="flex mr-2">
                <div className="rounded-md mt-5 w-10 ml-[100px] border border-zinc-600 ">
                  <FiArrowDown className="h-8 w-8 mt-8 ml-1 " />
                </div>
                <div className="mt-12 ml-3">
                  <p className="text-[8px]">TODAY SPENT</p>
                  <p className="text-[20px]">$3,840</p>
                </div>
              </div>
            </div>

            <div className="px-10">
              <div className="mt-4 mb-2 ">
                <p>History</p>
              </div>

              <div className="mt-1  flex justify-between rounded-md border border-slate-600">
                <div className="ml-3">
                  <p>24-06-2022</p>
                  <p className="text-[12px]">Receive from A</p>
                </div>
                <div className="mr-3 mt-3">
                  <p className="text-[#16a34a]"> + $1,000</p>
                </div>
              </div>

              <div className="mt-1  flex justify-between rounded-md border border-slate-600">
                <div className="ml-3">
                  <p>24-06-2022</p>
                  <p className="text-[12px]">Transfer to B</p>
                </div>
                <div className="mr-3 mt-3">
                  <p className="text-[#e11d48]"> - $2,130</p>
                </div>
              </div>

              <div className="mt-1  flex justify-between rounded-md border border-slate-600">
                <div className="ml-3">
                  <p>24-06-2022</p>
                  <p className="text-[12px]">Transfer to C</p>
                </div>
                <div className="mr-3 mt-3">
                  <p className="text-[#e11d48]"> - $1,710</p>
                </div>
              </div>

              <div className="mt-1  flex justify-between rounded-md border border-slate-600">
                <div className="ml-3">
                  <p>24-06-2022</p>
                  <p className="text-[12px]">Receive from A</p>
                </div>
                <div className="mr-3 mt-3">
                  <p className="text-[#16a34a]"> + $3,156</p>
                </div>
              </div>

              <div className="mt-1 flex justify-between rounded-md border border-slate-600">
                <div className="ml-3">
                  <p>24-06-2022</p>
                  <p className="text-[12px]">Receive from B</p>
                </div>
                <div className="mr-3 mt-3">
                  <p className="text-[#16a34a]"> + $12</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};
export default Index;
