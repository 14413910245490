import React, { useState } from "react";
import Datetime from "react-datetime";
import "./styles.css";
import { vietqr } from "../../assets";

const Datepicker = ({
  cls = "",
  title = "Date picker",
  placeHolder = "Select date",
}) => {
  const [value, setValue] = useState(null);
  const onChange = (v) => {
    setValue(v);
  };
  return (
    <div className={cls}>
      <span className="text-sm text-default truncate">
        <span>{title}</span>
        {/* {value && <span>: {value.format("DD-MM-YYYY")}</span>} */}
      </span>
      <Datetime
        defaultValue={new Date()}
        dateFormat="DD-MM-YYYY"
        timeFormat={false}
        input={true}
        inputProps={{
          className: "form-input",
          placeholder: placeHolder,
        }}
        viewMode={"days"}
        onChange={onChange}
      />
      {/* <img src={vietqr.calendar} alt="calendar" className="absolute right-0" /> */}
    </div>
  );
};

export default Datepicker;
