import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Centered from "./centered";
import Empty from "./empty";
import Layout1 from "./layout-1";
import LoginApp from "../pages/login-2";
import { useDispatch } from "react-redux";

const Layouts = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  let { pathname } = { ...location };

  if (
    [
      "/contact-us-1",
      "/logout",
      "/reset-password",
      "/forgot-password",
    ].includes(pathname)
  ) {
    return <Centered>{children}</Centered>;
  }

  if (
    [
      "/login-1",
      "/contact-us-1",
      "/create-account",
      "/email-confirmation",
      "/logout",
      "/reset-password",
      "/forgot-password",
      "/lock-screen",
      "/subscribe",
      "/error-page",
      "/coming-soon",
    ].includes(pathname)
  ) {
    return <Centered>{children}</Centered>;
  } else if (
    ["/landing", "/login", "/login-2", "/login-3"].includes(pathname)
  ) {
    return <Empty>{children}</Empty>;
  } else {
    return <Layout1>{children}</Layout1>;
  }
};

export default Layouts;
