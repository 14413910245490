import React, {useEffect, useState} from 'react'
import Validation from '../forms/validation'
import Alert from '../alerts'
import { useDispatch ,useSelector } from "react-redux";
import { SetForgotPassword ,SetPassword ,Getstatus} from "../../reducers/password.reducer";

const ForgotPassword = () => {
  const [data, onSubmit] = useState(null)
  const [message,setMessage] = useState()

  const dispatch = useDispatch()

  const call = useSelector(SetForgotPassword);
  const status = useSelector(Getstatus);

  useEffect(()=>{
    if(data != null){
      dispatch(SetPassword(data))
    }
  },[data])

  useEffect(()=>{
    if(status){

      setMessage("Thanks for your message. We'll get back to you as soon as possible")
    }else{
      console.log(call?.message)
      setMessage(call?.message)
    }
  },[status])
  let items = [
    {
      label: 'Email',
      error: {required: 'Please enter a valid email'},
      name: 'email',
      type: 'email',
      placeholder: 'Enter you email'
    },
  ]
  return (
    <>
      <div className="flex flex-col">
        {status && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-green-500 text-green-500"
              borderLeft
              raised>
              {message}
            </Alert>
          </div>
        )}
        {!status && status != null && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-red-500 text-red-500"
              borderLeft
              raised>
              {message}
            </Alert>
          </div>
        )}
        <Validation items={items} onSubmit={onSubmit} />
      </div>
    </>
  )
}

export default ForgotPassword
