import axiosClient from "./client_axios";
import { GET_MERCHANT_KEY_PORTAL, GET_MERCHANT_URL, ORDER_LIST_ITEMS } from "./endpoint";

export const checkoutService = {
  getListOrder: (params) => {
    return axiosClient.get(ORDER_LIST_ITEMS, { params });
  },

  getMerchantURL: (params) => {
    return axiosClient.get(GET_MERCHANT_URL, { params });
  },

  updateMerchantURL: (params) => {
    return axiosClient.put(GET_MERCHANT_URL, params);
  },

  getMerchantKeyReepay: (params) => {
    return axiosClient.get(GET_MERCHANT_KEY_PORTAL, { params });
  },

  createMerchantKeyReepay: (params) => {
    return axiosClient.post(GET_MERCHANT_KEY_PORTAL, params);
  },

};
