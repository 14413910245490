import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Alert from "../alerts";
import { FiAlertCircle } from "react-icons/fi";
import * as actions from "../../actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { history } from "../../helpers";

var md5 = require("md5");

const FormValidation = ({ items, onSubmit, alerts }) => {

  const dispatch = useDispatch();
  const { loading, error } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  const { handleSubmit, errors, register } = useForm();
  const onSubmitFn = async (data) => {
    if (onSubmit) {
      await dispatch(
        actions.authLogin({
          email: data.username,
          // domain: "atom-m-75-mms",
          password: md5(data.password),
        })
      );
    }
  };
  items = items.map((item) => {
    item["ref"] = register(item["error"]);
    return item;
  });

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      history.push('/');
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full"
    >
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null;
          let msg = errors[item.name].message;
          if (msg.length === 0) msg = `${item.label} is required`;
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised
                  >
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          );
        })}
      <div className="w-full">
        {items.map((item, i) => {
          if (item.type === "checkbox") {
            return (
              <div>
                <div className="flex justify-between">
                  <div className="form-element text-login-color-2 underline">
                    {item.label && (
                      <div className="form-label">{item.label}</div>
                    )}
                    <div className="flex items-center justify-start space-x-2">
                      {item.options.map((option, j) => (
                        <label className="flex items-center justify-start space-x-2">
                          <input
                            ref={item.ref}
                            type="checkbox"
                            value={option.value}
                            name={item.name}
                            className={`form-checkbox h-4 w-4 ${
                              errors[item.name] ? "text-red-500" : ""
                            }`}
                          />
                          <span
                            className={`${
                              errors[item.name] ? "text-red-500" : ""
                            }`}
                          >
                            {option.label}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                  <span className="underline">
                    <Link className="link" to="/forgot-password">
                      <p className="text-login-color-2">Quên mật khẩu?</p>
                    </Link>
                  </span>
                </div>
                {error && (
                  <div>
                    <Alert
                      color="bg-white dark:bg-grey-800 text-red-500"
                      raised
                      icon={
                        <FiAlertCircle className="mr-2 stroke-current h-4 w-4" />
                      }
                    >
                      Tên đăng nhập hoặc mật khẩu không chính xác!
                    </Alert>
                  </div>
                )}
              </div>
            );
          }
          return (
            <>
              <div className="form-element">
                {item.label && (
                  <div className="form-label text-login-color-5">
                    {item.label}
                    <span className="text-red-500">*</span>
                  </div>
                )}
                <input
                  ref={item.ref}
                  name={item.name}
                  type={item.type}
                  className={`form-input ${
                    errors[item.name] ? "border-red-500" : ""
                  }`}
                  placeholder={item.placeholder}
                />
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            </>
          );
        })}
      </div>
      <button
        type="submit"
        className="flex flex-row items-center justify-center space-x-4 w-full py-3 bg-red-500 hover:bg-red-600 rounded-md mt-5"
      >
        {loading && <ClipLoader color="white" size={17} />}
        <span className="font-semibold text-base text-white text-center">
          {loading ? "Đang đăng nhập..." : "Đăng nhập"}
        </span>
      </button>
    </form>
  );
};
export default FormValidation;
