/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import { AiOutlinePlusCircle } from "react-icons/ai";

import { Inputfield } from "../components/tsfillter/fillter.js";

import {} from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { fetchMerchantURL, updateMerchantURL } from "../actions";
import {
  resetUpdateUrlStatus,
  selectMerchantURL,
  selectUpdateMerchantURLStatus,
} from "../reducers/checkout.reducer";

const Config = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const merchantUrl = useSelector(selectMerchantURL);
  const updateMerchantUrlStatus = useSelector(selectUpdateMerchantURLStatus);
  useEffect(() => {
    dispatch(fetchMerchantURL());
  }, []);

  const onSubmit = async (data) => {
    await dispatch(updateMerchantURL(data));
    await dispatch(fetchMerchantURL());
    setTimeout(() => {
      dispatch(resetUpdateUrlStatus());
    }, 5000);
  };

  return (
    <div className="relative">
      <SectionTitle title="CẤU HÌNH" subtitle="THay đổi thông tin Merchant" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Widget>
            <div className="flex gap-12">
              <div className="w-full flex flex-col">
                <h1 className="text-lg font-semibold mb-2">
                  Sacombank Checkout
                </h1>
                <div className="grid grid-cols-2 gap-x-12">
                  <div className="w-full flex flex-col space-y-1">
                    <Inputfield
                      required
                      label="ReturnURL"
                      placeholder="Nhập ReturnURL"
                      field="return_url"
                      wraperstyle="w-full"
                      styleinput="w-full"
                      val={
                        merchantUrl?.return_url ? merchantUrl.return_url : ""
                      }
                    />
                  </div>
                  <div className="w-full flex flex-col space-y-1">
                    <Inputfield
                      required
                      label="CancelURL"
                      placeholder="Nhập CancelURL"
                      field="cancel_url"
                      wraperstyle="w-full"
                      styleinput="w-full"
                      val={
                        merchantUrl?.cancel_url ? merchantUrl.cancel_url : ""
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-12 mt-4">
                  <div className="w-full flex flex-col space-y-1">
                    <Inputfield
                      required
                      label="Ipn"
                      placeholder="Nhập ipn"
                      field="ipn"
                      wraperstyle="w-full"
                      styleinput="w-full"
                      val={merchantUrl?.ipn ? merchantUrl.ipn : ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Widget>
          <div className="relative flex flex-col items-center justify-center">
            {updateMerchantUrlStatus !== "" && (
              <p
                className={`absolute top-0 w-40 text-center ${
                  updateMerchantUrlStatus ? "text-green-600" : "text-red-600"
                }`}
              >
                {updateMerchantUrlStatus === "success"
                  ? "Cập nhật thành công!"
                  : "Cập nhật thất bại"}
              </p>
            )}
            <button
              type="submit"
              className="mt-8 w-40 px-6 py-3 text-white bg-blue-500 flex items-center gap-2 rounded-md"
            >
              <AiOutlinePlusCircle className="text-white h-4 w-4" />
              Thay đổi
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default Config;
