import React, { useEffect, useState } from "react";
import Widget1 from "../components/dashboard/widget-1";
import Section from "../components/dashboard/section";
import SectionTitle from "../components/dashboard/section-title";
import { FiActivity, FiUsers, FiExternalLink, FiClock } from "react-icons/fi";
import { Bar1 } from "../components/dashboard/bar-chart";
import { Donut1 } from "../components/dashboard/donut-chart";
import { Line1 } from "../components/dashboard/line-chart";
import Dropdown1 from "../components/widgets/dropdown-1";
import Markets from "../components/dashboard/markets";
import { List } from "../components/dashboard/list";
import Tasks from "../components/tasks";
import { Timeline1 } from "../components/timelines";
import { Select } from "../components/react-select/select";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as actions from "../actions";
import {
  selectDashboardReport,
  selectDashboardTotal,
} from "../reducers/dashboard.reducer";
import { formatCurrencyVND } from "../functions/numbers";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const dataTotal = useSelector(selectDashboardTotal);
  const barData = useSelector(selectDashboardReport);
  const [numOfMonthFilter, setNumOfMonthFilter] = useState(1);
  const [cardAmount, setCardAmount] = useState(null);
  const [qrAmount, setQrAmount] = useState(null);

  const options = [
    { value: 1, label: "1 tháng gần nhất" },
    { value: 3, label: "3 tháng gần nhất" },
    { value: 6, label: "6 tháng gần nhất" },
    { value: 9, label: "9 tháng gần nhất" },
    { value: 12, label: "12 tháng gần nhất" },
  ];

  useEffect(() => {
    dispatch(
      actions.fetchDashboardReport({
        time: 12,
      })
    );
  }, [location]);

  useEffect(() => {
    if (
      barData?.card_amount_transaction &&
      barData?.qr_code_amount_transaction
    ) {
      const objCard = barData.card_amount_transaction?.months;
      const objQr = barData.qr_code_amount_transaction?.months;
      const arrCard = [];
      Object.keys(objCard).forEach((key) =>
        arrCard.push({ value: objCard[key] })
      );
      setCardAmount(arrCard);

      const arrQr = [];
      Object.keys(objQr).forEach((key) => arrQr.push({ value: objQr[key] }));
      setQrAmount(arrQr);

      const sum = Object.values(objCard).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
    }
  }, [barData]);

  useEffect(() => {
    dispatch(
      actions.fetchDashboardReportFilter({
        time: numOfMonthFilter,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numOfMonthFilter]);

  return (
    <>
      <div className="flex ">
        <SectionTitle title="Overview" subtitle="Dashboard" />
        {/*select*/}
        <div className="w-full lg:w-1/4">
          <div className="p-2">
            <Select
              defaultValue={options[0]}
              options={options}
              onChange={(obj) => setNumOfMonthFilter(obj.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Số lượng giao dịch"
            description={dataTotal?.total_amount_transaction?.num}
            textColor="text-dbTxTotal"
            // right={
            //   <FiUsers size={24} className="stroke-current text-grey-500" />
            // }
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4 text-[#FF6230]">
          <Widget1
            title="Số tiền giao dịch"
            description={
              formatCurrencyVND(dataTotal?.total_amount_transaction?.sum) || 0
            }
            textColor="text-dbTxAmount"
            // right={
            //   <FiActivity size={24} className="stroke-current text-grey-500" />
            // }
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Số lượng GD thẻ"
            description={dataTotal?.card_amount_transaction?.num || 0}
            textColor="text-dbTxCardTotal"
            // right={
            //   <FiExternalLink
            //     size={24}
            //     className="stroke-current text-grey-500"
            //   />
            // }
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Số tiền GD thẻ"
            description={
              formatCurrencyVND(dataTotal?.card_amount_transaction?.sum) || 0
            }
            textColor="text-dbTxCardAmount"
            // right={
            //   <FiClock size={24} className="stroke-current text-grey-500" />
            // }
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 text-[#60CC7E]">
        <div className="w-full lg:w-2/3">
          <Section
            // title="Conversions"
            description={<span>Doanh thu</span>}
            // right={<Dropdown1 />}
          >
            <div className="flex flex-row w-full">
              {cardAmount && qrAmount && (
                <Bar1 cardAmount={cardAmount} qrAmount={qrAmount} />
              )}
            </div>
          </Section>
        </div>
        <div className="w-full lg:w-1/3">
          <Section
            // title="Sessions"
            description={<span>Hình thức giao dịch</span>}
            // right={<Dropdown1 />}
          >
            <div className="flex flex-row w-full">
              {barData && (
                <Donut1
                  cardNum={barData.card_amount_transaction?.num}
                  qrNum={barData.qr_code_amount_transaction?.num}
                />
              )}
            </div>
          </Section>
        </div>
      </div>

      {/* <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
        <Section
          title="Users"
          description={<span>Most important markets</span>}
        >
          <div className="flex flex-col w-full">
            <div className="overflow-x-scroll lg:overflow-hidden">
              <Markets />
            </div>
          </div>
        </Section>
      </div>

      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
        <div className="w-full lg:w-1/2">
          <Section
            title="Project status"
            description={<span>This week</span>}
            right={<Dropdown1 />}
          >
            <div className="flex flex-row w-full">
              <List />
            </div>
          </Section>
        </div>
        <div className="w-full lg:w-1/2">
          <Section
            title="Sales"
            description={<span>This month</span>}
            right={<Dropdown1 />}
          >
            <div className="flex flex-row w-full">
              <Line1 />
            </div>
          </Section>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
        <div className="w-full lg:w-1/3">
          <Section
            title="Activities"
            description={<span>Today</span>}
            right={<Dropdown1 />}
          >
            <div className="flex flex-row w-full">
              <Timeline1 />
            </div>
          </Section>
        </div>
        <div className="w-full lg:w-2/3">
          <Section
            title="To do"
            description={<span>In progress</span>}
            right={<Dropdown1 />}
          >
            <div className="flex flex-row w-full">
              <Tasks />
            </div>
          </Section>
        </div>
      </div> */}
    </>
  );
};
export default DashboardPage;
