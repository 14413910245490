export function formatCurrency(number){
    if(!number) return ""
    return number.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
}

export const getSubDomain = () => {
    let subDomain = window.location.host.split(".")[0];
    if(window.location.host.split(".")){
        if(process.env.NODE_ENV === "development"){
        //   subDomain = "atom-m-75-mms"
        }
        return subDomain
    }
    return null;
};

export const getFileName =(fileRefix,fileType)=> {
    let d = new Date();
    let dformat = `${d.getDate()}${d.getMonth()+1}${d.getFullYear()}`;
    return  `${fileRefix}_${dformat}.${fileType}`;
}