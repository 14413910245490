import React from 'react'
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import Ratings from '../components/e-commerce/ratings'
import Colors from '../components/e-commerce/colors'
import Brands from '../components/e-commerce/brands'
import Categories from '../components/e-commerce/categories'
import Products from '../components/e-commerce/products'
import {RangeSlider} from '../components/sliders'
import { FaHistory } from "react-icons/fa";
import {MdHistory} from "react-icons/md"
import {FaPaperPlane} from "react-icons/fa"
import {FiSettings} from "react-icons/fi"
// import {MdOutlineAttachMoney} from "react-icons/md"
import { AiFillCaretLeft } from "react-icons/ai";
import client from "../services/client_axios"
import { useEffect, useState } from 'react';


const Index = () => {

    const [accountBalance , setAccountBalance] = useState({
      })
      async function getBalance(){
        try{
    
          const body = {
            "partner_id": "62b1a0945fc5c34c15d2feb5",
            "lp_id": "232313213",
            "cex_code": "kraken"
        }
          const response = await client.post("/account/balance", body )
          if (response.status == 200) setAccountBalance(response.data.data.account_balance)
        }catch(error){
          console.log("error", error)
        }
      }
      useEffect(() => {
        getBalance()
      }, [])

  return (
    <>
      <SectionTitle title="Pages" subtitle="Kraken Balance" />
      <Widget>
        <div className="p-6 rounded-md h-full mx-10 w-1/2 border border-gray-300">
          <div className="flex justify-center relative h-1/3 ">
            <div className='w-2/5 text-center rounded-md border border-gray-300'>  
              <p className='text-[18px] '>TOTAL VALUE</p>
              <p className=' text-grey-500'>$81,213</p> 
              <div className='flex  justify-center mt-5 space-x-10'>
                <div>
                  <button className='rounded-full h-10 w-10  ' style={{background:"#71717a"}}>
                    <FaPaperPlane className=' m-3'/>                   
                  </button>
                  <p>Deposit</p>
                </div>      
                <div>
                  <button className='rounded-full h-10 w-10' style={{background:"#71717a"}}>
                    <FaHistory className='m-3'/>                   
                  </button>
                  <p>History</p>
                </div>                     
              </div> 
              <div className='mt-6 mb-4'>
                <p className='text-[16px] font-bold'>Kraken Exchange Wallet</p>
              </div>
              
            </div>
            <div className='absolute top-0 right-0 flex items-center space-x-2'>
              <div className='rounded-full w-4 h-4  '/>
              <FiSettings className='h-[3.5] '/>
            </div>
          </div>
          <div>
            <div>
              <p>My Tokens</p>
            </div>
            
            {Object.keys(accountBalance).map((item,index) => (
              <div key={index} className='mt-5 rounded-md border border-gray-300'>
                <div className='flex space-x-2 ml-3'>
                  {/* <img className="h-5 w-5 mt-1" src="/img/BTC.png" alt="bitcoin"/> */}
                  <p>{item}</p>
                  <p className='text-[12px] text-grey-500'>$1.001</p>
                </div>
                <div className='flex m-3  justify-between'>
                  <div>
                    <p className='text-[12px] '> Total</p>
                    <p> {accountBalance[item]}</p>
                    <p className='text-[12px] '> $21,213.1231</p>
                  </div>
                  <div>
                    <p className='text-[12px] '> Available</p>
                    <p> 0</p>
                    <p className='text-[12px] '> $0</p>
                  </div>
                  <div>
                    <p className='text-[12px] '> In Orders</p>
                    <p> 1.2311312141</p>
                    <p className='text-[12px] '> $21,213.1231</p>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </Widget>
    </>
  )
}
export default Index
