import React from "react";
import { Router } from "react-router-dom";
import Layouts from "./layouts";
import Routes from "./Routes";
import "./css/tailwind.css";
import "./css/main.css";
import "./css/animate.css";
import "./css/_components.css";
import "react-tabs/style/react-tabs.css";
import GetProfile from "./components/profile/GetProfile";
// import SubDomain from "./SubDomain";

import { history } from "./helpers";

const Wrapper = ({ children }) => {
  return <Layouts>{children}</Layouts>;
};

const App = () => {
  return (
    <Router history={history} basename="/portal">
      <Wrapper>
        <Routes />
      </Wrapper>
      {/* <SubDomain /> */}
      <GetProfile />
    </Router>
  );
};
export default App;
