import {getColor} from '../../functions/colors'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone
  return result
}

export const getItemStyle = (isDragging, draggableStyle) => {
  let background = isDragging
    ? getColor('bg-green-50')
    : getColor('bg-grey-100')
  return {
    background: background,
    ...draggableStyle
  }
}

export const getListStyle = isDraggingOver => {
  let background = isDraggingOver
    ? getColor('bg-blue-50')
    : getColor('bg-grey-50')
  return {
    background: background,
  }
}

