import { authService , userService } from "../services";
import { LocalStorageService , history } from "../helpers";
import { authTypes } from "../types";

export const authLogin = (dataReq) => async (dispatch, getState) => {
  if (dataReq) {
    
    const state = getState();
    
    const { domain } = state.auth;

    try {
      dispatch(request(dataReq));
      authService.login({
        ...dataReq,
        //domain
      }).then(
        async (res) => {
          const { data } = res;
          if (data && data.access_token) {
            console.log("🚀 ~ file: auth.actions.js:20 ~ data:", data)
            LocalStorageService.setToken(data)
            await dispatch(success(data))
            await dispatch(authGetProfile())
            history.push(`/dashboard`)
          }
          return data;
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    } catch (error) {
      dispatch(failure(error));
    }

    function request(data) {
      return { type: authTypes.AUTH_LOGIN_REQUEST, payload: data };
    }
    function success(data) {
      return { type: authTypes.AUTH_LOGIN_SUCCESS, payload: data };
    }
    function failure(error) {
      return { type: authTypes.AUTH_LOGIN_FAILURE, error };
    }
  }
};

export const authLogout = () => async (dispatch, getState) => {
  LocalStorageService.clearToken();
  await dispatch({ type: authTypes.AUTH_LOGOUT, payload: null });
};

export const authGetProfile = (dataReq) => async (dispatch, getState) => {

  const state = getState();

  const { domain } = state.auth;

  try {
    dispatch(request(dataReq));
    userService.getProfile({
      ...dataReq,
     // domain
    }).then(
      (res) => {
        const { data } = res;
        if (data) {
          dispatch(success(data));
        }
        return data;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  } catch (error) {
    dispatch(failure(error));
  }

  function request(data) {
    return { type: authTypes.AUTH_GET_PROFILE_REQUEST, payload: data };
  }
  function success(data) {
    return { type: authTypes.AUTH_GET_PROFILE_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: authTypes.AUTH_GET_PROFILE_FAILURE, error };
  }
};


export const getSubDomainURI = ({subDomain}) => async (dispatch, getState) => {
  dispatch(success(subDomain))
  function success(data) {
    return { type: authTypes.GET_SUBDOMAIN_SUCCESS, payload: data };
  }

};
