import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { state } from "faker/lib/locales/en/address";
import { userService } from "../services/user.service";

const initialState = {
    items: null,
    pending: false,
    status:null
};

const password = createSlice({
    name: "password",
    initialState,
    reducers: {
        resetState: (state, action) => {
            state.items = null;
            state.status = null
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(SetPassword.pending, (state, action) => {
                state.pending = true
                state.status = null
            })
            .addCase(SetPassword.fulfilled, (state, action) => {
                state.items = action.payload;
                state.pending = false
                state.status = true
            })
            .addCase(SetPassword.rejected, (state, action) => {
                state.items = action.error;
                state.pending = false
                state.status = false
            }),

});

export const SetPassword = createAsyncThunk('portal/ForgotPassword', async (data, { dispatch ,getState},thunkAPI) => {
    try {
        const state = getState();
        const { domain } = state.auth;
        const _data = await userService.ForgotPassword({
            ...data,
            domain
        });
        return _data;
    } catch (error) {
        const message = error
        throw new Error(message.errors || message.msg)

    }
});

export default password.reducer;

export const {resetState} = password.actions;

export const SetForgotPassword = (state) => state.password.items;

export const Getstatus = (state) => state.password.status;

export const Getpending = (state) => state.password.pending;
