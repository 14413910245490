import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchListTransaction } from "../actions";
import { TSService } from "../services/transaction.service";

const initialState = {
  items: null,
  pending:false,
};

const transactionSlice = createSlice({
  name: "transactionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchListTransaction.pending, (state, action) => {
        state.pending = true
      })
      .addCase(fetchListTransaction.fulfilled, (state, action) => {
        state.items = action.payload;
        state.pending = false
      })
      .addCase(fetchListTransaction.rejected, (state, action) => {
        state.items = [];
        state.pending = false
       }),

});

export const getListTS = createAsyncThunk("transaction/getListTS", async (data,{dispatch}) => {
  const _data = await TSService.getListTransaction(data);
  return _data;
});

export default transactionSlice.reducer;

export const { } = transactionSlice.actions;

export const selectTSSliceDashboard = (state) => state.transaction.items;
