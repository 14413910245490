import React, { useCallback, useEffect, memo, useRef } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  actions,
} from "react-table";
import { PageWithText } from "../pagination";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Sacombank from "../../assets/images/ic-sacombank.svg";
import TablePagination from "./pagination";
import PropTypes from "prop-types";
import { fetchDownloadImage } from "../../actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";
import { ClipLoader } from "react-spinners";
import moment from "moment";

export const StatusCell = ({ data }) => {
  
  if (data.values.response_code !== "00") {
    return (
      <div className="mx-auto rounded-full max-w-144px text-red-error-text bg-red-error-bg py-2 px-6 flex justify-center items-center">
        <span className=" text-4xl leading-3 mr-1">&#x2022;</span> <p>Lỗi</p>
      </div>
    );
  } else if (data.values.response_code === "00" && data.values.is_void === 1) {
    return (
      <div className="mx-auto rounded-full max-w-144px text-yellow-700 bg-yellow-100 py-2 px-6 flex justify-center items-center">
        <span className=" text-4xl leading-3 mr-1">&#x2022;</span>{" "}
        <p>Thành Công</p>
      </div>
    );
  } else if (data.values.response_code === "00") {
    return (
      <div className="mx-auto rounded-full max-w-144px text-green-success-text bg-green-success-bg py-2 px-6 flex justify-center items-center">
        <span className=" text-4xl leading-3 mr-1">&#x2022;</span>{" "}
        <p>Thành Công</p>
      </div>
    );
  }
};

export const ReceiptCell = ({ data }) => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const handleAfterPrint = useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    setLoading(true);
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, []);

  const handleOnBeforeGetContent = useCallback(async () => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    // const imgUrl = await dispatch(
    //   fetchDownloadImage({ image_url: data?.original?.signature_data })
    // );
    
   // setLoading(true);
    
    //setImage(imgUrl);
    //https://s3-hn-2.cloud.cmctelecom.vn/paydi-billing-prod/2023/03/12/f61b6018-5b9c-48fa-90fe-97b6f38bdebd
  //const imgURL = "https://s3-hn-2.cloud.cmctelecom.vn/paydi-billing-prod/2023/03/11/f61b6018-5b9c-48fa-90fe-97b6f38bdebd"
  //  const imgURL = "https://s3-hn-2.cloud.cmctelecom.vn/paydi-billing-prod/2023/03/12/f61b6018-5b9c-48fa-90fe-97b6f38bdebd"
  //   setImage(imgURL);

  //   return new Promise((resolve) => {
  //     onBeforeGetContentResolve.current = resolve;
  //     setTimeout(() => {
  //       setLoading(false);
  //       resolve();
  //     }, 2000);
  //   });
  }, [setLoading, setImage]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    //onBeforeGetContent: handleOnBeforeGetContent,
     onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (image && typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, image]);

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-row items-center justify-between space-x-2">
        {loading && <ClipLoader color="#565E85" size={14} />}
        <button
          onClick={handlePrint}
          className="text-sm font-semibold underline"
        >
          {loading ? "Printing" : "Print"}
        </button>
      </div>

      <div className="hidden">
        <ComponentToPrint ref={componentRef} image={data?.original?.signature_data} />
      </div>

    </div>
  );
};
export const Organization = ({ data }) => {
  return (
    <div>
      <span className="flex gap-3 justify-center border-b border-bluetext fit">
        <img src={Sacombank} alt="Sacombank" />
        <p>{data.original.Organization}</p>
      </span>
    </div>
  );
};

export const TransactionTypeCell = ({ data }) => {
  // var label = "N/A";
  // if (data.original.transaction_type === "sale") {
  //   if (data.original.response_code !== "00") {
  //     label = "Lỗi";
  //   } else {
  //     label = data.original.is_void === 0 ? "Sale" : "Void";
  //   }
  // }
  return (
    <div>
      <span className="border-b border-bluetext fit capitalize">
        {data.original.transaction_type}
      </span>
    </div>
  );
};

export const ConvertDateTime = ({ data }) => {
  return moment(
    new Date(data?.values?.trans_date_time * 1000)
  ).format("HH:mm:ss DD-MM-YYYY")
}

export const ConvertStatusBank = ({ data }) => {
  if(data?.values?.status_tranx === 'pending') {
    return 'Chờ duyệt'
  }else if (data?.values?.status_tranx === 'accept') {
    return 'Phê duyệt'
  }else {
    return 'Từ chối'
  }
}

export const ConvertPeriod = ({ data }) => {
  return `${data?.values?.period} tháng`
}

function Datatable(props) {
  const {
    columns,
    data,
    total_request_amount,
    pagination,
    onPaginationChange,
  } = props;
  const isEmpty = !data.length;

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
      manualPagination: true,
      autoResetPage: false,
      pageCount: pagination.totalPages,
    },
    useSortBy,
    usePagination
  );

  const handlePaginationChange = useCallback(() => {
    onPaginationChange({ page: pageIndex + 1, pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  useEffect(() => {
    handlePaginationChange();
  }, [handlePaginationChange]);

  const total = total_request_amount;

  // Render the UI for your table
  return (
    <>
      <table
        {...getTableProps()}
        className="table font-montserrat  text-grey-atom-grey"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex flex-col items-center justify-center text-sm text-grey-atom-grey font-semibold">
                    <span>{column.render("Header")}</span>
                    {/* Add a sort direction indicator */}
                    <span className="ml-auto">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FiChevronDown className="stroke-current text-2xs" />
                        ) : (
                          <FiChevronUp className="stroke-current text-2xs" />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className="h-20 text-center" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot className="relative">
          <tr className="absolute pt-8 right-0 font-bold text-atomblue text-xl text-right">
            <td>Tổng Cộng: {total}</td>
          </tr>
        </tfoot>
      </table>

      <div className="flex flex-row items-center justify-between my-4 pt-16">
        {!isEmpty && (
          <TablePagination
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
          />
        )}
        <select
          className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
          value={pagination?.pageSize}
          onChange={(e) => {
            onPaginationChange({
              ...pagination,
              page: 1,
              pageSize: Number(e.target.value),
            });
          }}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
Datatable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  headerClass: PropTypes.string,
  loading: PropTypes.bool,
};
export default memo(Datatable);
