import React, { useEffect, useState } from "react";
import { FiBox, FiMenu } from "react-icons/fi";
import ILogoPaydiFull from "../../assets/images/LogoPaydi.svg?p=2";
import ILogoPaydi from "../../assets/images/Logo.svg";
import ILogoAtomFull from "../../assets/images/LogoAtomFull.svg";
import ILogoAtom from "../../assets/images/LogoAtom.svg";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";

const Logo = () => {
  const dispatch = useDispatch();
  const { config, leftSidebar } = useSelector(
    (state) => ({
      config: state.config,
      leftSidebar: state.leftSidebar,
    }),
    shallowEqual
  );
  const { name, collapsed } = { ...config };
  const { showLogo } = { ...leftSidebar };

  const [logo, setLogo] = useState(ILogoAtom);
  const [logoFull, setLogoFull] = useState(ILogoAtomFull);

  useEffect(() => {
    if (window.location.hostname.includes("paydi")) {
      setLogo(ILogoPaydi);
      setLogoFull(ILogoPaydiFull);
    }
  }, []);
  useEffect(() => {
    // console.log("leftSidebar", leftSidebar);
  }, [leftSidebar]);

  if (showLogo) {
    return (
      <div className="logo truncate">
        <Link
          to="/"
          className="flex flex-row items-center justify-start space-x-2"
        >

          {collapsed ? (
            <img src={logo} alt="logo" className="w-3/5" />
          ) : (
            <img src={logoFull} alt="logo" className="w-full" />
          )}
        </Link>
        <button
          onClick={() =>
            dispatch({
              type: "SET_CONFIG_KEY",
              key: "collapsed",
              value: !collapsed,
            })
          }
          className="ml-auto mr-4 block lg:hidden"
        >
          <FiMenu size={20} />
        </button>
      </div>
    );
  }
  return null;
};

export default Logo;
