import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Login from "../components/forms/login";
import * as actions from "../actions";
import ILogoPaydi from "../assets/images/LogoPaydi.svg";
import ILogoAtom from "../assets/images/LogoAtomFull.svg";


import Icon1 from "../assets/images/bg-login-2.png?p=123";

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, onSubmit] = useState(null);
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual
  );
  let { name } = { ...config };
  let items = [
    {
      label: "Tên đăng nhập",
      error: { required: "Vui lòng nhập tên đăng nhập" },
      name: "username",
      type: "username",
      placeholder: "Nhập số điện thoại",
    },
    {
      label: "Mật khẩu",
      error: {
        required: "Vui lòng nhập mật khẩu",
      },
      name: "password",
      type: "password",
      placeholder: "Nhập mật khẩu",
    },
    {
      label: "",
      error: {},
      name: "terms",
      type: "checkbox",
      options: [{ value: true, label: "Lưu đăng nhập" }],
    },
  ];

  useEffect(() => {
    dispatch(actions.authLogout());
    history.push("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="w-full flex flex-row h-screen overflow-hidden justify-center items-center "
        style={{ backgroundImage: "url(/bg-login.png)" }}
      >
        <div className=" w-2/3 h-1/2 flex border mt-10 justify-center rounded-lg shadow-md">
          <div className="hidden lg:flex lg:flex-col w-1/2 h-1/2">
            <img
              src={Icon1}
              className="object-contain rounded-l-lg"
            />
          </div>
          <div className="bg-login-color-1 w-full lg:w-1/2 flex flex-col items-center justify-center py-5 lg:py-1">
            <div className="flex justify-center w-full">
              <img
                src={
                  window.location.hostname.includes("paydi")
                    ? ILogoPaydi 
                    : ILogoAtom
                }
                className="h-full w-1/2 pb-10"
              />
            </div>
            <div className="flex justify-center items-center w-2/3">
              <Login items={items} onSubmit={onSubmit} alerts={false} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
