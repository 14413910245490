import React, { useEffect, useState } from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const PageWidget = () => {

  const codeString =`${'\n'}<script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/widget.js"></script>
  ${'\n'}<div id="widget-app"></div>
  ${'\n'}<script type="text/javascript">
  ${'\n'}WidgetASM.config({
    ${'\n'}appID: "fec4b00a-15c7-42d1-8a16-16658b43"
    ${'\n'}selector: "#widget-app",
    ${'\n'}order:{
    ${'\n'}"order_id":"1",
    ${'\n'}"amount": 10000,
    ${'\n'}"mobile": "0327888663",
    ${'\n'}"gender": "M",
    ${'\n'}"address": "address",
    ${'\n'}"email": "huuhanh811@gmail.com",
    ${'\n'}"description": "description",
    ${'\n'}"last_name": "last_name",
    ${'\n'}"city": "city",
    ${'\n'}"country": "country",
    ${'\n'}"district": "7",
    ${'\n'}"first_name": "Nguyen"
  ${'\n'}})
  ${'\n'}WidgetASM.widgets.init().render()
  ${'\n'}</script>${'\n'}`;

  useEffect(() => {
  //   WidgetASM.config({
  //     appID: "fec4b00a-15c7-42d1-8a16-16658b43",
  //     selector: "#widget-app",
  //     order:{
  //         "order_id":"1",
  //         "amount": 10000,
  //         "mobile": "0327888663",
  //         "gender": "M",
  //         "address": "address",
  //         "email": "huuhanh811@gmail.com",
  //         "description": "",
  //         "last_name": "Hanh",
  //         "city": "HCM",
  //         "country": "VN",
  //         "district": "7",
  //         "first_name": "Nguyen",
  //         "is_redirect": false
  //     }
  // });
  // WidgetASM.widgets.init().render()
  },[])

  return (
    <section className="flex items-start justify-between mt-8 p-20">
      <div className="form-group">
        <label >Code:</label>
        <SyntaxHighlighter 
            language="javascript"
            style={dark}
            htmlScript={true}
            startingLineNumber="1"
            customStyle={{
                  padding: "15px 20px", 
                  margin: 0 ,
                  overflow:'hidden', 
                  background:"#1D242F", 
                  color:"#FFF" , 
                  fontSize:"12px" , 
                  borderRadius:"4px" ,
                  border:"none"
            }}
            >
            {codeString} 
        </SyntaxHighlighter>
      </div>
      <div>
          <label>View</label>
          <div id="widget-app"></div>
      </div>
    </section>
  );
};
export default PageWidget;
