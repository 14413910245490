import axiosClient from "./client_axios";
import { Forgot_Password } from "./endpoint"

const route = "/portal";

export const userService = {
  getProfile: (params) => {
    return axiosClient.get(`${route}/merchant_info`, {params});
  },
  ForgotPassword: (params) => {
    return axiosClient.post(Forgot_Password, params)
  },
};
