import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import { FiSearch } from "react-icons/fi";
import { CiExport } from "react-icons/ci";
import Datatable from "../components/tsdatatable/datatables";
import { StatusCell, Organization } from "../components/tsdatatable/datatables";
import {
  Datetime,
  Optionfield,
  Searchfield,
} from "../components/tsfillter/fillter.js";
import {} from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import moment from "moment";
import { formatCurrency } from "../utils/lib";
import { selectListOrder } from "../reducers/checkout.reducer";
import { shallowEqual } from "react-redux";
import { getListOrder } from "../actions";

const DatatableComponent = ({
  customData,
  filters,
  numOfPage,
  data,
  onPaginationChange,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "STT",
        accessor: (row) => row.index,
        disableSortBy: true,
      },
      {
        Header: "Thời gian GD",
        accessor: "order_info.TransactionDateTime",
      },
      {
        Header: "Mã giao dịch",
        accessor: "order_info.TransactionID",
      },
      {
        Header: "Tổ chức phát hành",
        accessor: "order_info.Country",
        Cell: ({ value, row }) => <Organization data={row} />,
      },
      {
        Header: "Số tiền",
        accessor: "order_info.TotalAmount",
      },
      {
        Header: "Số thẻ",
        accessor: "order_info.Description",
        Cell: ({ value, row }) => "--",
      },
      {
        Header: "Kết quả giao dịch",
        accessor: "status",
      },
    ],
    []
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      total_request_amount={data?.total_amount}
      onPaginationChange={onPaginationChange}
    />
  );
};
const optionTsStatus = [
  { label: "Tất cả", value: "all" },
  { label: "Thành Công", value: "success" },
  { label: "Lỗi", value: "error" },
  { label: "Void", value: "void" },
];
const optionTStype = [
  { label: "Sale", value: "sale" },
  { label: "void", value: "void" },
  { label: "pre_auth", value: "pre_auth" },
  { label: "complete_pre_auth", value: "complete_pre_auth" },
  { label: "all", value: "all" },
];
const optionStatus = [
  { label: "transaction_id", value: "transaction_id" },
  { label: "customer_id", value: "customer_id" },
  { label: "card_number", value: "card_number" },
];

const Checkout = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const listData = useSelector(selectListOrder);
  const { userProfile } = useSelector((state) => state.auth, shallowEqual);
  const [numOfPage, setnumOfPage] = useState("1");
  const formdata = useRef();
  // const data = [
  //   {
  //     STT: "01",
  //     TransactionTime: "16:20 22/12/2022",
  //     CodeOrders: "123123",
  //     Organization: "Sacombank",
  //     Amount: 5000000,
  //     CardNumber: "123123******1234",
  //     Status: "1",
  //   },
  //   {
  //     STT: "01",
  //     TransactionTime: "16:20 22/12/2022",
  //     CodeOrders: "123123",
  //     Organization: "Sacombank",
  //     Amount: 5000000,
  //     CardNumber: "123123******1234",
  //     Status: "2",
  //   },
  // ];
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });
  const customData = useMemo(() => {
    setnumOfPage(listData?.data?.num_of_page);
    return listData?.data?.items;
  }, [listData]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e, index) => {
          return {
            ...e,
            request_amount: formatCurrency(e.request_amount),
            original_transaction_date: moment(
              new Date(e.original_transaction_date * 1000)
            ).format("HH:mm:ss DD-MM-YYYY"),
            index: (filters.page - 1) * filters.page_size + index + 1,
          };
        })
      : [];
  };

  const handlePaginationChange = useCallback((pagination) => {
    setFilters({
      page_size: pagination.pageSize,
      page: pagination.page,
      ...formdata.current,
    });
  }, []);

  useEffect(() => {
    if (userProfile?.merchant_id) {
      dispatch(
        getListOrder({ ...filters, merchant_id: userProfile.merchant_id })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, userProfile]);

  function removeEmptyFields(data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
      if (!data["query_key"] || !data["query_value"]) {
        delete data["query_key"];
        delete data["query_value"];
      }
    });
  }
  const onSubmit = (data) => {
    removeEmptyFields(data);
    data.from_date = moment(data.from_date).format("DD/MM/YYYY");
    data.to_date = moment(data.to_date).format("DD/MM/YYYY");

    formdata.current = data;
    setFilters(formdata.current);
    console.log(data);
  };
  return (
    <>
      <SectionTitle title="BÁO CÁO PAYMENT HUB" subtitle="Check Out" />
      <Widget>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex col-span-3 gap-12">
              <Datetime label="Thời gian" field="from_date" field2="to_date" />
              <Searchfield
                label="Tìm kiếm"
                option={optionStatus}
                inputwidth="w-full"
                fieldwidth="w-1/2"
                field="query_value"
              />
              <Optionfield
                label="Tổ chức phát hành"
                field="field"
                option={optionTStype}
              />
              <Optionfield
                label="Trạng thái"
                option={optionTsStatus}
                field="status"
              />{" "}
            </div>
            <div className="flex gap-8 mt-8">
              <button
                type="submit"
                className="px-6 py-3 text-white bg-blue-500 flex items-center gap-2 rounded-md"
              >
                <FiSearch className="text-white h-4 w-4" />
                Tìm kiếm
              </button>
              <button className="px-6 py-3 text-white bg-green-500 flex items-center gap-2 rounded-md">
                <CiExport className="text-white h-4 w-4" />
                Xuất Excel
              </button>
            </div>
          </form>
        </FormProvider>
      </Widget>

      {listData?.data && customData && (
        <div className="mt-8">
          <Widget>
            <DatatableComponent
              data={listData.data}
              customData={formatData(customData)}
              filters={filters}
              numOfPage={numOfPage}
              onPaginationChange={handlePaginationChange}
            />
          </Widget>
        </div>
      )}
    </>
  );
};
export default Checkout;
