import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import { FiSearch } from "react-icons/fi";
import { CiExport } from "react-icons/ci";
import queryString from "query-string";


import Datatable, {
  TransactionTypeCell,
} from "../components/tsdatatable/datatables";
import { StatusCell, ReceiptCell } from "../components/tsdatatable/datatables";
import {
  Datetime,
  Inputfield,
  Optionfield,
  Searchfield,
} from "../components/tsfillter/fillter.js";
import {
  selectTSSliceDashboard,
} from "../reducers/transaction.reducer";
import {} from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import moment from "moment";
import { date } from "faker/lib/locales/en";
import { formatCurrency, getFileName } from "../utils/lib";
import { exportOrderTransaction, fetchListTransaction } from "../actions";
import { LocalStorageService } from "../helpers";
import { GET_URL_EXPORT_TRANSACTION } from "../services/endpoint";
import { TSService } from "../services";
import axiosClient from "../services/client_axios";

const DatatableComponent = ({
  customData,
  filters,
  numOfPage,
  data,
  onPaginationChange,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "STT",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Thời gian GD",
        accessor: "original_transaction_date",
      },
      {
        Header: "Mã đơn hàng",
        accessor: "invoice_no",
      },
      {
        Header: "Số thẻ",
        accessor: "card_no",
      },
      {
        Header: "Số tiền",
        accessor: "request_amount",
      },
      {
        Header: "Số tiền TIP",
        accessor: "tip",
      },
      {
        Header: "Loại giao dịch",
        accessor: "transaction_type",
        Cell: ({ value, row }) => <TransactionTypeCell data={row} />,
      },
      {
        Header: "Trạng thái",
        accessor: "response_code",
        Cell: ({ value, row }) => <StatusCell data={row} />,
      },
      {
        Header: "Serial Number",
        accessor: "serial_no",
      },
      {
        Header: "Receipt",
        accessor: "Receipt",
        Cell: ({ value, row }) => <ReceiptCell data={row} />,
      },
    ],
    []
  );

  // const data = React.useMemo(() => countries, [])
  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      total_request_amount={formatCurrency(data?.data?.total_request_amount)}
      onPaginationChange={onPaginationChange}
    />
  );
};
const optionTsStatus = [
  { label: "Tất cả", value: "all" },
  { label: "Thành Công", value: "success" },
  { label: "Lỗi", value: "error" },
  { label: "Void", value: "void" },
];
const optionTStype = [
  { label: "Tất cả", value: "all" },
  { label: "Sale", value: "sale" },
  // { label: "void", value: "void" },
  { label: "Pre-auth", value: "pre_auth" },
  { label: "Complete Pre-auth", value: "complete_pre_auth" },
];
const optionStatus = [
  { label: "Chọn trường", value: "" },
  { label: "Mã đơn hàng", value: "transaction_id" },
  { label: "Số thẻ (06 số đầu - 04 số cuối)", value: "card_number" },
  { label: "Tất cả", value: "all" },
];

const TransactionCard = () => {

  const methods = useForm();
  const dispatch = useDispatch();
  const data = useSelector(selectTSSliceDashboard);
  const { domain } = useSelector((state) => state.auth, shallowEqual);

  const [numOfPage, setnumOfPage] = useState("1");
  const formdata = useRef();

  var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
  var firstDay = new Date(y, m, 2);
  var lastDay = new Date(y, m + 1, 0);

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
    status: 'all',
    from_date: moment(firstDay.toISOString().split("T")[0]).format("DD/MM/YYYY"),
    to_date: moment(lastDay.toISOString().split("T")[0]).format("DD/MM/YYYY"),
    type: 'all',
  });
  
  const customData = useMemo(() => {
    if (!data?.data?.items) return [];
    setnumOfPage(data?.data?.num_of_page);
    return data?.data?.items;
  }, [data]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
          return {
            ...e,
            request_amount: formatCurrency(e.request_amount),
            original_transaction_date: moment(
              new Date(e.original_transaction_date * 1000)
            ).format("HH:mm:ss DD-MM-YYYY"),
          };
        })
      : [];
  };

  const handlePaginationChange = useCallback((pagination) => {
    setFilters({
      page_size: pagination.pageSize,
      page: pagination.page,
      ...formdata.current,
    });
  }, []);

  useEffect(() => {
    dispatch(fetchListTransaction(filters));
  }, [filters]);

  function removeEmptyFields(data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
      if (!data["query_key"] || !data["query_value"]) {
        delete data["query_key"];
        delete data["query_value"];
      }
    });
  }

  const onSubmit = (data) =>{

    const buttonType= window.event.submitter.name;

    if(buttonType=== "search"){
      onSubmitFilter(data)
    }

    if(buttonType=== "export"){
      onClickExportDowwnload(data)
    }

  }

  const onSubmitFilter = (data) => {
    removeEmptyFields(data);
    data.from_date = moment(data.from_date).format("DD/MM/YYYY");
    data.to_date = moment(data.to_date).format("DD/MM/YYYY");

    formdata.current = data;
    setFilters(formdata.current);
  };

  const onClickExportDowwnload = async (values) => {

   values.from_date = moment(values.from_date).format("DD/MM/YYYY");
   values.to_date = moment(values.to_date).format("DD/MM/YYYY");

   if(Object.keys(values).length > 0){
      const fileURL = process.env.REACT_APP_API_ENDPOINT 
      + `/${GET_URL_EXPORT_TRANSACTION}` + '?' 
      + queryString.stringify({...values},{
      // + queryString.stringify({...values,domain},{
        skipEmptyString: true,
        skipNull: true
      })
      downloadFile2(fileURL)
   }
    
  }
  
  const downloadFile2 = async (fileURL) => {
     axiosClient.get(fileURL, { 
      // headers: { 
      //   // "Authorization": authHeader().Authorization,
      //  // "Accept" : "application/octet-stream"            
      // },
      responseType: 'blob' 
    }).then(response => {


      if(response){
      // ["\ufeff", response]
        // response.data is an empty object
        const blob = new Blob(["\ufeff", response], {
          type: 'application/octet-stream;charset=utf-8',
        });

        var csv = "\ufeff"+'csv';
        const url = window.URL.createObjectURL(blob); // you can mention a type if you wish
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", getFileName('txn','csv')); //this is the name with which the file will be downloaded
        link.click();
        // link.parentNode.removeChild(link)
      }

    });

  }
  
  const downloadFile = (fileURL) => {

    fetch(fileURL, {
      method: 'GET',
      headers: {
        "Content-Type": "application/octet-stream",
        'Authorization': `Bearer ${LocalStorageService.getAccessToken()}`
      },
    })
    .then((response) => response.blob())
    .then((response) => {

      const blob = new Blob(["\ufeff", response], {
        type: 'application/octet-stream;charset=utf-8',
      });
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')

      a.href = url;
      a.download = getFileName('txn','csv');
      a.click();

      // Create blob link to download
      // const url = window.URL.createObjectURL(
      //   new Blob([blob]),
      // );
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute(
      //   'download',
      //   `transaction.xlsx`,
      // );

      // Append to html link element page
      // document.body.appendChild(link)

      // // Start download
      // link.click()

      // // Clean up and remove the link
      // link.parentNode.removeChild(link)

    });
  }

  return (
    <>
      <SectionTitle title="BÁO CÁO GIAO DỊCH" subtitle="Giao dịch thẻ" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Widget>
            <div className="flex gap-12">
              <Optionfield
                label="Loại giao dịch"
                option={optionTStype}
                field="type"
              />
              <Inputfield
                label="Serial Number"
                placeholder="Nhập Serial Number"
                field="serial_number"
              />
              <Optionfield
                label="Trạng thái"
                option={optionTsStatus}
                field="status"
              />
            </div>
            <div className="mt-12 flex gap-12">
              <Datetime label="Thời gian" field="from_date" field2="to_date" />
              <Searchfield
                label="Tìm kiếm"
                option={optionStatus}
                inputwidth="w-full"
                fieldwidth="w-1/2"
                field="query_value"
              />
            </div>
          </Widget>
          <div className="flex gap-8 mt-8">
            <button
              type="submit"
              name="search"
              className="px-6 py-3 text-white bg-blue-500 flex items-center gap-2 rounded-md"
            >
              <FiSearch className="text-white h-4 w-4" />
              Tìm kiếm
            </button>
            <button
              type="submit"
              name="export"
              className="px-6 py-3 text-white bg-green-500 flex items-center gap-2 rounded-md cursor-pointer">
              <CiExport className="text-white h-4 w-4" />
              Xuất Excel
            </button>
          </div>
        </form>
      </FormProvider>
      <div className="mt-8">
        <Widget>
          <DatatableComponent
            customData={formatData(customData)}
            filters={filters}
            numOfPage={numOfPage}
            data={data}
            onPaginationChange={handlePaginationChange}
          />
        </Widget>
      </div>
    </>
  );
};
export default TransactionCard;
