import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import { FiSearch } from "react-icons/fi";
import { CiExport } from "react-icons/ci";
import Datatable, {
  TransactionTypeCell,
} from "../components/tsdatatable/datatables";
import { StatusCell, ReceiptCell } from "../components/tsdatatable/datatables";
import {
  Datetime,
  Inputfield,
  Optionfield,
  Searchfield,
} from "../components/tsfillter/fillter.js";
import { } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import moment from "moment";
import { formatCurrency } from "../utils/lib";

const DatatableComponent = ({ customData,
  filters,
  numOfPage,
  data,
  onPaginationChange, }) => {

  const columns = React.useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'STT'
      },
      {
        Header: 'Thời gian GD',
        accessor: 'TransactionTime'
      },
      {
        Header: 'Mã đơn hàng',
        accessor: 'CodeOrders'
      },
      {
        Header: 'Số tiền',
        accessor: 'Amount',
      },
      {
        Header: 'Trạng thái',
        accessor: 'Status',
        Cell: ({ value, row }) => <StatusCell data={row} />,

      },
      {
        Header: 'Tài khoản nguồn',
        accessor: 'Rootaccount',

      },

    ],
    []
  )
  // const data = React.useMemo(() => countries, [])
  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      total_request_amount={100000000}
      onPaginationChange={onPaginationChange}
    />
  );
}
const optionTsStatus = [
  { label: "Tất cả", value: "all" },
  { label: "Thành Công", value: "success" },
  { label: "Lỗi", value: "error" },
  { label: "Void", value: "void" }
]

const optionStatus = [
  { label: "transaction_id", value: "transaction_id" },
  { label: "customer_id", value: "customer_id" },
  { label: "card_number", value: "card_number" },
]

const VietQR = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  // const data = useSelector(selectTSSliceDashboard);
  const [numOfPage, setnumOfPage] = useState("1");
  const formdata = useRef();
  const data = [
    {
      "STT": "01",
      "TransactionTime": "16:20 22/12/2022",
      "CodeOrders": "123123",
      "Amount": 5000000,
      "Status": "1",
      "Rootaccount": "123456789123",
    },
    {
      "STT": "01",
      "TransactionTime": "16:20 22/12/2022",
      "CodeOrders": "123123",
      "Amount": 5000000,
      "Status": "2",
      "Rootaccount": "123456789123",
    }
  ]
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });
  const customData = useMemo(() => {
    setnumOfPage(1);
    return data;
  }, []);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
        return {
          ...e,
          request_amount: formatCurrency(e.request_amount),
          original_transaction_date: moment(
            new Date(e.original_transaction_date * 1000)
          ).format("HH:mm:ss DD-MM-YYYY"),
        };
      })
      : [];
  };

  const handlePaginationChange = useCallback((pagination) => {
    setFilters({
      // page_size: pagination.pageSize,
      // page: pagination.page,
      page_size: 1,
      page: 1,
      ...formdata.current,
    });
  }, []);

  function removeEmptyFields(data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
      if (!data["query_key"] || !data["query_value"]) {
        delete data["query_key"];
        delete data["query_value"];
      }
    });
  }
  const onSubmit = (data) => {
    removeEmptyFields(data);
    data.from_date = moment(data.from_date).format("DD/MM/YYYY");
    data.to_date = moment(data.to_date).format("DD/MM/YYYY");

    formdata.current = data;
    setFilters(formdata.current);
  };
  return (
    <>
      <SectionTitle title="BÁO CÁO PAYMENT HUB" subtitle="VietQR" />
      <Widget>
        <FormProvider {...methods} >

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex col-span-3 gap-12">
              <Datetime label="Thời gian" field="from_date" field2="to_date" />
              <Searchfield
                label="Tìm kiếm"
                option={optionStatus}
                inputwidth="w-auto"
                fieldwidth="w-auto"
                field="query_value"
              />
              <Optionfield
                label="Trạng thái"
                option={optionTsStatus}
                field="status" />
            </div>
            <div className="flex gap-8 mt-8">
              <button
                type="submit"
                className="px-6 py-3 text-white bg-blue-500 flex items-center gap-2 rounded-md"
              >
                <FiSearch className="text-white h-4 w-4" />
                Tìm kiếm
              </button>
              <button className="px-6 py-3 text-white bg-green-500 flex items-center gap-2 rounded-md">
                <CiExport className="text-white h-4 w-4" />
                Xuất Excel
              </button>
            </div>
          </form>
        </FormProvider>
      </Widget>

      <div className="mt-8">
        <Widget>
          <DatatableComponent
            customData={formatData(customData)}
            filters={filters}
            numOfPage={numOfPage}
            data={data}
            onPaginationChange={handlePaginationChange} />
        </Widget>
      </div>
    </>
  );
};
export default VietQR;
