import React from 'react'
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import Ratings from '../components/e-commerce/ratings'
import Colors from '../components/e-commerce/colors'
import Brands from '../components/e-commerce/brands'
import Categories from '../components/e-commerce/categories'
import Products from '../components/e-commerce/products'
import {RangeSlider} from '../components/sliders'
import { FiSettings } from "react-icons/fi";
import { useEffect, useState, useRef} from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";

const ws = new W3CWebSocket('wss://ws.kraken.com');

const Index = () => {

    const [orderBook , setOrderBook] = useState([
        // "bid":0,
        // "qty_bid":0,
        // "ask":0,
        // "qty_ask":0
    
      ])
    const [price,setPrice] = useState({
    "bid":0,
    "ask":0
    })

    const api_book = {'bid':[], 'ask':[]};
    const api_depth = 10;

    const api_output_book = () => {
        let bid = api_book['bid'].sort((x, y) => parseFloat(y[0])-parseFloat(x[0]));
        let ask = api_book['ask'].sort((x, y) => parseFloat(x[0])-parseFloat(y[0]));
        console.log ('Bid\t\t\t\t\tAsk');

        let arr=[];
        for (let x=0;x<5;x++) {
        console.log(`${bid[x][0]} (${bid[x][1]})\t\t\t${ask[x][0]} (${ask[x][1]})`);
        arr.push({
            "bid":`${bid[x][0]}`,
            "qty_bid":`${bid[x][1]}`,
            "ask":`${ask[x][0]}`,
            "qty_ask":`${ask[x][1]}`
        })
        setPrice({
            "bid":`${bid[x][0]}`,
            "ask":`${ask[x][0]}`
        })
        setOrderBook(arr)
        }
        
    }

    const api_update_book = (side, data) => {
        data.forEach((e) => {
            let index = api_book[side].findIndex(o => o[0] == e[0]);
            if (parseFloat(e[1]) > 0){
                if(index < 0){
                    api_book[side].push([e[0],e[1]]);
                } else {
                    api_book[side][index] = [e[0],e[1]];
                }
            } else {
                api_book[side].splice(index,1);
            }
        });

        if(side=='bid'){
            api_book['bid'].sort((x, y) => parseFloat(y[0])-parseFloat(x[0]));
        } else if(side=='ask'){
            api_book['ask'].sort((x, y) => parseFloat(x[0])-parseFloat(y[0]));
        }

    }

    useEffect(() => {
    ws.onopen = () => {
        ws.send('{"event":"subscribe", "subscription":{"name":"book", "depth":'+api_depth+'}, "pair":["XBT/USD"]}');
        console.log('Kraken websocket connected!');
    };
    ws.onmessage = (data) =>  {
        try {
            let data1 = JSON.parse(data.data);
            // console.log(data1[1])
            if (data1[1]) {
                if (data1[1]['as']) {
                    api_update_book('ask', data1[1]['as'])
                    api_update_book('bid', data1[1]['bs'])
                } else if (data1[1]['a'] || data1[1]['b']) {
                    if (data1[1]['a']) {
                        api_update_book('ask', data1[1]['a']);
                    }
                    if (data1[1]['b']) {
                        api_update_book('bid', data1[1]['b']);
                    }
                }
    
                api_output_book();
            } 
    
    
        } catch (error) {
            console.log(error);
        }  
    };
    }, [])

  return (
    <>
      <SectionTitle title="Pages" subtitle="OrderBook" />
      <Widget>
        <div className="p-6 bg-[#27272a] rounded-[4px] items-center w-full h-2/3">
            <div className="flex justify-center relative ">
                <div className="flex mr-3" >
                    <img className="h-5 w-5 mt-1" src="/img/BTC.png" alt="bitcoin"/>
                    <div class="text-xl font-medium text-[16px] pl-2">BTC</div>
                </div>
                <div className="flex">
                    <img className="h-5 w-5 mt-1" src="/img/USDT.png" alt="tether"/>
                    <div class="text-xl font-medium text-[16px] pl-2">USDT</div>
                </div>
                <div className='absolute top-0 right-0 flex items-center space-x-2'>
                    <div className='rounded-full bg-[#16a34a] w-4 h-4  '/>
                    <FiSettings className='h-[3.5] '/>
                </div>
                

            </div>
            <div className="flex m-2 justify-between text-center my-4">
                <p className='w-1/3 text-[26px]'>{price.bid}</p>
                <p className='w-1/3 justify-center flex'><div className='bg-[#52525b] w-1/3 rounded-[4px] text-[12px] my-2'>3.5</div></p>          
                <p className='w-1/3 text-[26px]'>{price.ask}</p>
            </div>
            <div className='justify-center flex my-1'>
                <div className='bg-[#18181b] w-1/3 text-center text-[16px] border border-slate-600'>10</div>
            </div>
            
            <div className='w-full justify-between flex space-x-1'>
                <button className='bg-[#52525b] rounded-[4px] w-1/3 text-[16px]'> <p>Bid</p> </button>
                <button className='bg-[#52525b] rounded-[4px] w-1/3 text-[16px]'>RFG</button>
                <button className='bg-[#52525b] rounded-[4px] w-1/3 text-[16px]'>Ask</button>
            </div>
            <div className='w-full text-center '>
                <table className='w-full'>
                <thead>
                    <tr className='text-[12px]'>
                    <th >QTY</th>
                    <th>Bid</th>
                    <th>SPREAD(BPS)</th>
                    <th>Ask</th>
                    <th>QTY</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {orderBook.map((item, index) => (
                    <tr key={index}>
                    <td> <div className='bg-[#18181b] rounded-[4px] text-[14px] border border-slate-600'>{item.qty_bid}</div></td>
                    <td><div className='text-[18px]'>{item.bid}</div></td>
                    <td className='justify-center flex'><div className='bg-[#52525b] w-1/2 text-[12px] my-2 rounded-[4px]' >1</div></td>
                    <td><div className='text-[18px]'>{item.ask}</div></td>
                    <td><div className='bg-[#18181b] rounded-[4px] text-[14px] border border-slate-600'>{item.qty_ask}</div></td>
                    </tr>
                    )) }
                </tbody>
                </table>
            </div>
            
        </div>
      </Widget>
    </>
  )
}
export default Index
