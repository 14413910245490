/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import { AiOutlinePlusCircle } from "react-icons/ai";

import { Inputfield } from "../components/tsfillter/fillter.js";

import {} from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { fetchMerchantKeyReepay,  createMerchantKeyReepay } from "../actions";
import {
  resetUpdateKeyStatus,
  selectMerchantKey,
  selectUpdateMerchantKEYStatus,
} from "../reducers/checkout.reducer";

const ConfigReepay = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const merchantKey = useSelector(selectMerchantKey);
  const updateMerchantStatus = useSelector(selectUpdateMerchantKEYStatus);
  useEffect(() => {
   dispatch(fetchMerchantKeyReepay());
  }, []);

  const onSubmit = async (data) => {
    await dispatch(createMerchantKeyReepay(data));
    await dispatch(fetchMerchantKeyReepay());
    setTimeout(() => {
      dispatch(resetUpdateKeyStatus());
    }, 3500);
  };

  useEffect(() => {
    if(updateMerchantStatus === "success"){
      resetFrm()
    }
  }, [updateMerchantStatus]);
  
  const resetFrm = () => { 
    document.getElementById("create-frm-reepay").reset();
  }

  return (
    <div className="relative">
      <SectionTitle title="CẤU HÌNH" subtitle="Thiết lập Ree-Pay" />
      <FormProvider {...methods}>
        <form id="create-frm-reepay" onSubmit={methods.handleSubmit(onSubmit)}>
          <Widget>
            <div className="flex gap-12">
              <div className="w-full flex flex-col">
                <h1 className="text-lg font-semibold mb-2">
                    Mua trước trả sau Ree-Pay
                </h1>
                <div className="flex flex-col gap-5">
                  <div className="w-full flex flex-col space-y-1">
                    <Inputfield
                      required
                      label="Token Key"
                      placeholder="Nhập Token Key"
                      field="token_key"
                      wraperstyle="w-full"
                      styleinput="w-full"
                      // val={
                      //   merchantKey?.token_key ? merchantKey.token_key : ""
                      // }
                    />
                  </div>
                  <div className="w-full flex flex-col space-y-1">
                    <Inputfield
                      required
                      label="Encrypt Key"
                      placeholder="Nhập Encrypt Key"
                      field="encrypt_key"
                      wraperstyle="w-full"
                      styleinput="w-full"
                      // val={
                      //   merchantKey?.encrypt_key ? merchantKey.encrypt_key : ""
                      // }
                    />
                  </div>
                  <div className="w-full flex flex-col space-y-1">
                    <Inputfield
                      required
                      label="Checksum Key"
                      placeholder="Nhập checksum key"
                      field="checksum_key"
                      wraperstyle="w-full"
                      styleinput="w-full"
                      // val={merchantKey?.checksum_key ? merchantKey.checksum_key : ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Widget>
          <div className="relative flex flex-col items-center justify-center">
            {updateMerchantStatus !== "" && (
              <p
                className={`absolute top-0 w-40 text-center ${
                  updateMerchantStatus ? "text-green-600" : "text-red-600"
                }`}
              >
                {updateMerchantStatus === "success"
                  ? "Cập nhật thành công!"
                  : "Cập nhật thất bại"}
              </p>
            )}
            <button
              type="submit"
              className="mt-8 w-40 px-6 py-3 text-white bg-blue-500 flex items-center gap-2 rounded-md"
            >
              <AiOutlinePlusCircle className="text-white h-4 w-4" />
              Cập nhật
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default ConfigReepay;
