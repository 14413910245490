import React, { useState, useEffect } from 'react'
import ic_lock from "../assets/images/icon/ic-lock.svg"
import ic_mail from "../assets/images/icon/ic-mail.svg"
import ic_load from "../assets/images/icon/loadding.svg"

import { useForm } from 'react-hook-form'
// import Alert from '../alerts'
import { useDispatch, useSelector } from "react-redux";
import { SetForgotPassword, SetPassword, Getstatus, resetState, Getpending } from "../reducers/password.reducer";
import { useHistory } from "react-router-dom";

const Index = () => {
  const { handleSubmit, errors, register } = useForm()

  const [data, onSubmit] = useState(null)

  const [message, setMessage] = useState()

  const dispatch = useDispatch()

  const call = useSelector(SetForgotPassword);
  const status = useSelector(Getstatus);
  const pending = useSelector(Getpending);

  let history = useHistory();

  const onSubmitFn = data => {
    if (onSubmit) {
      onSubmit(data)
    }
  }
  //reset state
  const reset = () => {
    dispatch(resetState())
  }
  //send email
  useEffect(() => {
    if (data != null) {
      dispatch(SetPassword(data))
    }
  }, [data])
  //error alert
  useEffect(() => {
    if (!status && status != null) {
      setMessage(call?.message)
    }
  }, [status])
  useEffect(() => {
    setMessage("")
  }, [errors["email"]])
  //reset state when change page
  useEffect(() => reset, [])
  // hidden email
  function maskPII(str) {
    const indexOfAt = str.indexOf('@');
    if (indexOfAt <= 2) {
      return str;
    }
  
    return str[0] + '*'.repeat(indexOfAt - 2) + str.substring(indexOfAt - 1);
  }
  
  return (
    <>
      <div
        className="w-full flex flex-row h-screen overflow-hidden justify-center items-center "
        style={{ backgroundImage: "url(/bg-login.png)", backgroundSize: "cover" }}
      >
        <div className='passwordForm-bg flex flex-col w-full max-w-md p-8'>
          <div className="w-20 h-8">
            <img src="/ATOM-Logo.png" />
          </div>
          {!status ? (
            <>
              <div className='w-full flex flex-col items-center text-login-color-5'>
                <img src={ic_lock} className="w-8 h-10" />
                <p className='text-xl mt-3 text-login-color-5 font-semibold'>Quên mật khẩu?</p>
                <p className='text-sm mt-6 text-center'>Nhập email và ATOM sẽ gửi cho bạn một liên kết để truy cập lại vào tài khoản.</p>
              </div>
              <form
                onSubmit={handleSubmit(onSubmitFn)}
                className="form w-full flex flex-col mt-3">
                <div className='form-input relative flex justify-center items-center p-0'>
                  <input
                    ref={register({ required: true })}
                    name="email"
                    type="email"
                    className={`w-full pr-10 px-3 py-2 ${errors["email"] ? 'border-red-500' : ''
                      }`}
                    placeholder="Vui lòng nhập email"
                  />
                  <div className={`lds-dual-ring pr-4 ${pending ? "inline-block" : "hidden"}`} />
                </div>
                <div className="form-error text-red-500 h-4 mt-4">
                  {errors["email"] ? (
                    "Please enter a valid email"
                  ) : (
                    message
                  )}

                </div>
                <div className='gap-4 flex flex-col mt-8'>
                  <input
                    type="submit"
                    value="Nhập liên kết đăng nhập"
                    className="btn btn-default font-semibold bg-blue-500 hover:bg-blue-600 text-white rounded"
                  />
                  <input
                    type="submit"
                    onClick={history.goBack}
                    value="Trở lại"
                    className="btn btn-default font-semibold bg-red-500 hover:bg-red-600 text-white rounded"
                  />
                </div>
              </form>
            </>
          ) : (
            <>
              <div className='w-full flex flex-col items-center text-login-color-5'>
                <img src={ic_mail} className="w-8 h-10" />
                <p className='text-xl mt-3  text-login-color-5 font-semibold'>Đã gửi email</p>
                <p className='text-sm mt-6 text-center'>ATOM đã gửi email đến <span className="  text-login-color-5 font-semibold">{maskPII(data?.email)}</span> kèm theo liên kết để bạn truy cập lại vào tài khoản. Vui lòng kiểm tra email của bạn</p>
              </div>

              <div className='gap-4 flex flex-col mt-10'>
                <input
                  type="submit"
                  onClick={reset}
                  value="Gửi lại liên kết đăng nhập"
                  className="btn btn-default font-semibold bg-blue-500 hover:bg-blue-600 text-white rounded"
                />
                <input
                  type="submit"
                  onClick={history.goBack}
                  value="Trở lại"
                  className="btn btn-default font-semibold bg-red-500 hover:bg-red-600 text-white rounded"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>

  )
}

export default Index
