// import Axios from "axios";

// const client = Axios.create({
//     baseURL: process.env.REACT_APP_BASE_URL,
// });

// // client.interceptors.request.use((config) => {
// //     if (!config.headers["x-token"]) {
// //         const LoginToken = localStorage.getItem('Nekot-covid');
// //         if (LoginToken) {
// //             config.headers["x-token"] = LoginToken;
// //         }
// //     }
// //     return config;
// // });

// export default client;

import axios from "axios";
import queryString from "query-string";

import { history, LocalStorageService } from "../helpers";
import { getSubDomain } from "../utils/lib";
const localStorageService = LocalStorageService.getService();

//import { osName, osVersion ,browserName ,isMobile ,mobileModel ,mobileVendor ,browserVersion } from 'react-device-detect';
//const deviceName = isMobile ? (mobileVendor + " " + mobileModel + " " + browserName):   browserName + ' ' + browserVersion;

const defaultHeader = {
  // "Access-Control-Allow-Origin": "*",
  // "Content-Type": "application/json",
  // Accept: "application/json",
  //'versionapp': localStorage.getItem("versionapp") || '1.0.0',

  // 'clientid': process.env.REACT_APP_CLIENT_ID,
  // 'hashcode': process.env.REACT_APP_CLIENT_HASH,
  // 'versionos': osVersion + ' ' + osName,
  // 'devicename': deviceName
};

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
  baseURL: API_ENDPOINT,
  //headers: defaultHeader,
  paramsSerializer: (params) => queryString.stringify(params),
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    return handleResponse(response);
  },
  (error) => {
    const originalRequest = error.config;
    console.log("🚀 ~ file: client_axios.js:90 ~ error:", error)
    if (error.response.status === 403 
      && error.response.data.error_code === "E_FORBIDDEN" 
      &&  !originalRequest._retry) {
      console.log("HET^! TOKEN ");

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosClient.request(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorageService.getRefreshToken()
      const accessTokenExp = localStorageService.getAccessToken()

      if(!refreshToken){
        history.push('/login')
      }

      return new Promise(function (resolve, reject) {

        const domain = getSubDomain()

        axios.post(
            `${API_ENDPOINT}/portal/authenticate/refresh_token`,
            { 
              refresh_token: refreshToken ,
             // domain
            },
            {
              headers: {
                ...defaultHeader,
                "Authorization": `Bearer ${accessTokenExp}`,
              },
            }
          )
          .then((res) => {

            const { data } = res.data;

            // 1) put token to LocalStorage
            localStorageService.setToken(data);

            // 2) Change Authorization header
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.accessToken;
            originalRequest.headers["Authorization"] =
              "Bearer " + data.accessToken;

            processQueue(null, data.accessToken)

            // 3) return originalRequest object with Axios
            resolve(axiosClient.request(originalRequest))

          })
          .catch((err) => {

            console.log("-------err.response",err)
            if(err && err?.error_code === "REFRESH_TOKEN_INVALID"){
              clearAuthToken()
            }

            if(err && err.response ){
              const {error_code} = err.response?.data;
              if(error_code === "REFRESH_TOKEN_INVALID"){
                clearAuthToken()
              }
            }

            processQueue(err, null)
            reject(err)

          })
          .finally(() => {
            isRefreshing = false;
          });
      });
      
    }

    return Promise.reject(handleError(error));
  }
);

const handleResponse = (res) => {
  console.log("handleResponse",res);
  if (res && res.data) {
    return res.data;
  }

  return res;
};

const handleError = (error) => {
  const { data } = error.response;

  if (data && data.error_code === "USER_LOCKED_USING") {
    clearAuthToken();
  }

  return data;
};

const clearAuthToken = () => {
  LocalStorageService.clearToken();
  history.push("/login");
};

export default axiosClient;
