import React ,{useEffect} from "react"
import clsx from "clsx"
import map from "lodash/map"
import "./pagination.css"
import PropTypes from "prop-types"

function Pagination(props) {
  const {
    canPreviousPage,
    previousPage,
    pageOptions,
    pageIndex,
    canNextPage,
    gotoPage,
    nextPage
  } = props

  return (
    <div className="pagination">
      <button
        className={clsx(
          "pagination__prev flex flex-row justify-center items-center",
          {
            active: canPreviousPage
          }
        )}
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        {/* <img src={iconLeft} alt="btn_prev" />  */}
        <span className="text-2xl text-login-color-5 pagination__button flex items-center">{"< <"}</span>
      </button>
      {map(pageOptions, (item) => {
        return pageOptions.length > 10 ? (
          <>
            {pageIndex > 5 ? (
              <>
                {item < 5 && (
                  <div
                    className={`pagination__item pagination__number text-base ${
                      pageIndex === item ? "active" : ""
                    }`}
                    onClick={() => {
                      gotoPage(item)
                    }}
                    key={item}
                  >
                    {item + 1}
                  </div>
                )}
                {item === 6 && (
                  <div
                    className={`pagination__item pagination__number text-base`}
                    onClick={() => {
                      gotoPage(item)
                    }}
                    key={item}
                  >
                    ...
                  </div>
                )}
                {item > pageOptions.length - 5 && (
                  <div
                    className={`pagination__item pagination__number text-base ${
                      pageIndex === item ? "active" : ""
                    }`}
                    onClick={() => {
                      gotoPage(item)
                    }}
                    key={item}
                  >
                    {item + 1}
                  </div>
                )}
              </>
            ) : (
              <>
                {item < 5 && (
                  <div
                    className={`pagination__item pagination__number text-base ${
                      pageIndex === item ? "active" : ""
                    }`}
                    onClick={() => {
                      gotoPage(item)
                    }}
                    key={item}
                  >
                    {item + 1}
                  </div>
                )}
                {item === 6 && (
                  <div
                    className={`pagination__item pagination__number text-base`}
                    onClick={() => {
                      gotoPage(item)
                    }}
                    key={item}
                  >
                    ...
                  </div>
                )}
                {item > pageOptions.length - 5 && (
                  <div
                    className={`pagination__item pagination__number text-base ${
                      pageIndex === item ? "active" : ""
                    }`}
                    onClick={() => {
                      gotoPage(item)
                    }}
                    key={item}
                  >
                    {item + 1}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div
            className={`pagination__item pagination__number text-base ${
              pageIndex === item ? "active" : ""
            }`}
            onClick={() => {
              gotoPage(item)
            }}
            key={item}
          >
            {item + 1}
          </div>
        )
      })}
      <button
        className={clsx(
          "pagination__next flex flex-row justify-center items-center",
          {
            active: canNextPage
          }
        )}
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        <span className="text-2xl text-login-color-5 pagination__button flex items-center">{"> >"}</span>
        {/* <img className="rotate-180" src={iconLeft} alt="btn_next" /> */}
      </button>
    </div>
  )
}

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool,
  previousPage: PropTypes.func,
  pageOptions: PropTypes.any,
  pageIndex: PropTypes.any,
  canNextPage: PropTypes.bool,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.func
}

export default Pagination
