import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { isDarkPalette } from "../../functions/colors";
import { singleSelectStyle } from "./theme";

export const Select = ({ ...props }) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  const { background } = { ...palettes };
  const isDark = isDarkPalette(background);
  return (
    <ReactSelect
      {...props}
      styles={singleSelectStyle(isDark ? "primary" : "secondary")}
      isMulti={false}
    />
  );
};
