export const share = {
  logo: require("./images/Logo.svg"),
};

export const checkout = {
  payment1: require("./images/checkout/payment1.svg"),
  payment2: require("./images/checkout/payment2.svg"),
  qrcode: require("./images/checkout/qrcode.svg"),
};

export const vietqr = {
  calendar: require("./images/vietqr/calendar.svg"),
  search: require("./images/vietqr/search.svg"),
};
