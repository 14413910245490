import React from "react";
import { share } from "../../assets";

const PaymentMethod = ({ item, activeMethod }) => {
  return (
    <div className="flex flex-row items-start justify-between space-x-4">
      <div className="flex flex-row items-start space-x-4">
        {item.icon && <img src={item.icon} alt="payment" />}
        <div className="flex flex-col space-y-2">
          <span className="font-medium text-base text-blue-1">
            {item.description}
          </span>
          <div className="flex flex-row items-center">
            <span className="font-medium text-sm text-grey-8">Powered by</span>
            <img src={share.logo} alt="logo" className="w-5 ml-2" />
            <span className="ml-1 font-bold text-sm text-atom-black">
              ATOM Solution
            </span>
          </div>
        </div>
      </div>

      {item.radio && (
        <input
          type="radio"
          value={item.id}
          checked={activeMethod.id === item.id}
          readOnly={true}
          // name={item.name}
          className="form-radio text-blue-6 h-4 w-4 mt-1"
        />
      )}
    </div>
  );
};

export default PaymentMethod;
