import SectionTitle from "../components/section-title";
import React, { Component, useEffect, useState, useMemo, useCallback, useRef  }  from 'react';
import { useForm, FormProvider } from "react-hook-form";
import Widget from "../components/widget";
import {
    Datetime,
    Inputfield,
    Optionfield,
    Searchfield,
} from "../components/tsfillter/fillter.js";
import { FiSearch } from "react-icons/fi";
import { CiExport } from "react-icons/ci";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { fetchListTransInstallment } from "../actions";
import { selectTransactionInstallment } from "../reducers/transinstallment.reducer";
import { formatCurrency, getFileName } from "../utils/lib";
import Datatable, {
  ConvertDateTime,
  ConvertStatusBank,
  ConvertPeriod,
  } from "../components/tsdatatable/datatables";
import { StatusCell, ReceiptCell } from "../components/tsdatatable/datatables";
import moment from "moment";

const DatatableComponent = ({
    customData,
    filters,
    numOfPage,
    data,
    onPaginationChange,
  }) => {
    const columns = React.useMemo(
      () => [
        {
          Header: "STT",
          accessor: (row, i) => i + 1,
          disableSortBy: true,
        },
        {
          Header: "Thời gian GD",
          accessor: "trans_date_time",
          Cell: ({ value, row }) => <ConvertDateTime data={row} />,
        },
        {
          Header: "Mã đơn hàng",
          accessor: "invoice_no",
        },
        {
            Header: "Mã giao dịch",
            accessor: "trace_no",
        },
        {
          Header: "Số thẻ",
          accessor: "card_number",
        },
        {
          Header: "Kỳ hạn trả góp",
          accessor: "period",
          Cell: ({ value, row }) => <ConvertPeriod data={row} />,
        },
        {
          Header: "Trạng thái",
          accessor: "status_tranx",
          Cell: ({ value, row }) => <ConvertStatusBank data={row} />,
        },
        {
          Header: "Số tiền phê duyệt",
          accessor: "total_amount",
        //   Cell: ({ value, row }) => <TransactionTypeCell data={row} />,
        },
        {
          Header: "Serial Number",
          accessor: "serial_number",
        },
      ],
      []
    );
  
    // const data = React.useMemo(() => countries, [])
    return (
      <Datatable
        columns={columns}
        data={customData}
        pagination={{
          page: filters.page,
          pageSize: filters.page_size,
          totalPages: numOfPage,
        }}
        total_request_amount={formatCurrency(data?.total_request_amount)}
        onPaginationChange={onPaginationChange}
      />
    );
};

const optionStatus = [
    { label: "Chọn trường", value: "" },
    { label: "Mã đơn hàng", value: "invoice_no" },
    { label: "Mã giao dịch", value: "trace_no" },
    { label: "Số thẻ (06 số đầu - 04 số cuối)", value: "card_number" },
    { label: "Tất cả", value: "all" },
  ];

const optionTsStatus = [
    { label: "Tất cả", value: "all" },
    { label: "Chờ duyệt", value: "pending" },
    { label: "Phê duyệt", value: "accept" },
    { label: "Từ chối", value: "reject" },
  ];

const TransactionInstallment = () => {
    const methods = useForm();
    const dispatch = useDispatch()
    const data = useSelector(selectTransactionInstallment);
    const [numOfPage, setnumOfPage] = useState("1");
    const formdata = useRef();

    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 2);
    var lastDay = new Date(y, m + 1, 0);

    const [filters, setFilters] = useState({
        page: 1,
        page_size: 10,
        status: 'all',
        from_date: moment(firstDay.toISOString().split("T")[0]).format("DD/MM/YYYY"),
        to_date: moment(lastDay.toISOString().split("T")[0]).format("DD/MM/YYYY")
    });

    const customData = useMemo(() => {
        if (!data?.items) return [];
        setnumOfPage(data?.num_of_page);
        return data;
    }, [data]);

  
    const handlePaginationChange = useCallback((pagination) => {
          setFilters({
            page_size: pagination.pageSize,
            page: pagination.page,
            ...formdata.current,
          });
    }, []);

    useEffect(() => {
      dispatch(fetchListTransInstallment(filters));
    }, [filters]);

    function removeEmptyFields(data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === "" || data[key] == null) {
          delete data[key];
        }
        if (!data["query_key"] || !data["query_value"]) {
          delete data["query_key"];
          delete data["query_value"];
        }
      });
    }

    const onSubmit = (data) =>{
      const buttonType= window.event.submitter.name;
      if(buttonType=== "search"){
          onSubmitFilter(data)
      }

      if(buttonType=== "export"){
          // onClickExportDowwnload(data)
      }
    }
    
    const onSubmitFilter = (data) => {
      removeEmptyFields(data);

      data.from_date = moment(data.from_date).format("DD/MM/YYYY");
      data.to_date = moment(data.to_date).format("DD/MM/YYYY");
      
      formdata.current = data;
      setFilters(formdata.current);
    };

    useEffect(() => {
        console.log('customData', customData)
    }, [customData]);
    useEffect(() => {
        console.log('data', data)
    }, [data]);

    return (
        <>
            <SectionTitle title="BÁO CÁO GIAO DỊCH" subtitle="Giao dịch trả góp" />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Widget>
                        <div className="mt-12 flex gap-24">
                            <Datetime label="Thời gian" field="from_date" field2="to_date" />

                            <Inputfield
                              label="Serial Number"
                              placeholder="Nhập Serial Number"
                              field="serial_number"
                            />
                            
                        </div>
                        <div className="mt-12 flex gap-24">
                            <Optionfield
                              label="Trạng thái"
                              option={optionTsStatus}
                              field="status"
                            />
                          <Searchfield
                              label="Tìm kiếm"
                              option={optionStatus}
                              inputwidth="w-full"
                              fieldwidth="w-1/2"
                              field="query_value"
                          />
                        </div>
                    </Widget>
                    <div className="flex gap-8 mt-8">
                        <button
                            type="submit"
                            name="search"
                            className="px-6 py-3 text-white bg-blue-500 flex items-center gap-2 rounded-md"
                        >
                            <FiSearch className="text-white h-4 w-4" />
                            Tìm kiếm
                        </button>
                        <button
                            type="submit"
                            name="export"
                            className="px-6 py-3 text-white bg-green-500 flex items-center gap-2 rounded-md cursor-pointer">
                            <CiExport className="text-white h-4 w-4" />
                            Xuất Excel
                        </button>
                    </div>
                </form>
            </FormProvider>
            <div className="mt-8">
                <Widget>
                {data?.items?.length>0&&<DatatableComponent
                    customData={data?.items}
                    filters={filters}
                    numOfPage={numOfPage}
                    data={data}
                    onPaginationChange={handlePaginationChange}
                />}
                </Widget>
            </div>
        </>
    );
};

export default TransactionInstallment;